<template>

  <session-layout>

    <template slot="session-title">
      <h3>Enter your email address</h3>
    </template>

    <template slot="session-body">

          <v-text-field
                  label="Email"
                  v-model="email"
                  required
                  :rules="emailRules"
                  validate-on-blur
                  v-on:keyup.enter="submit">
          </v-text-field>
          <a class="d-block text-right remember" @click="$router.push('/session/login')">Go to login page</a>
          <v-btn
                  depressed
                  :disabled="passwordReseting || !is_valid_email"
                  class="mt-10"
                  style="width: 100%"
                  @click="submit">
            Send Email
            <v-progress-circular
                    v-if="passwordReseting"
                    style="position: absolute; right: 30%;"
                    indeterminate
                    :size="22"
                    color="#fff"
            ></v-progress-circular>
          </v-btn>

    </template>

  </session-layout>

</template>

<script>
  import SessionLayout from './Layout'
  import { mapState } from 'vuex'
   
  export default {
    name: 'ResetPassword',
    computed: {
      ...mapState({
        passwordReseting: state => state.auth.passwordReseting,
      }),
      is_valid_email: function() { return /^.+@.+\..+$/.test(this.email); }
    },
    data() {
      return {
        valid: false,
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ]
      }
    },
    methods:{
      submit(){
        if(!this.passwordReseting && this.is_valid_email){
          const payload = {
            email:this.email,
            host: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2]
          }
          
          this.$store.dispatch('resetPassword',payload)
        }
        return false
      }
    },
    components: {
      SessionLayout
    }
  }
</script>